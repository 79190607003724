import { useState } from 'react';

import { Spinner, ToggleSwitch } from '@components/index';

import { IntegrationActionConfirmationModal } from './IntegrationActionConfirmationModal';
import { useGetIntegrationStatus } from '../hooks/use-get-integration-status';
import { useManageIntegrationStatus } from '../hooks/use-manage-integration-status';

const IntegrationOptions = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { isLoading: isFetchingStatus } = useGetIntegrationStatus(setIsEnabled);

  const { mutate: toggleIntegrationStatus, isLoading } =
    useManageIntegrationStatus();

  const handleToggleSwitch = (status: boolean) => {
    if (!status) {
      setIsConfirmationModalOpen(true);
    } else {
      setIsEnabled(status);
      toggleIntegrationStatus({
        isEnabled: status,
        onError: () => {
          setIsEnabled(!status);
        },
      });
    }
  };

  const handleConfirmToggleOff = () => {
    setIsEnabled(false);
    toggleIntegrationStatus({
      isEnabled: false,
      onError: () => {
        setIsEnabled(true);
      },
      onSuccess: () => setIsConfirmationModalOpen(false),
    });
  };

  return (
    <div className="flex flex-col px-8 py-6">
      <div className="flex h-[102px] border border-gray-6 px-6 rounded-md items-center justify-between">
        <div className="flex flex-col gap-2">
          <div className="font-bold text-lg">Integration with Pencil</div>
          <div className="text-gray-3 text-[15px]">
            Seamlessly allowing integration of Brand DNAi Workspace into the
            Pencil workspace for enhanced collaboration.
          </div>
        </div>
        {isFetchingStatus ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <ToggleSwitch
            isChecked={isEnabled}
            onChange={handleToggleSwitch}
            isDisabled={isLoading || isFetchingStatus}
          />
        )}
      </div>
      {isConfirmationModalOpen && (
        <IntegrationActionConfirmationModal
          isOpen={isConfirmationModalOpen}
          isLoading={isLoading}
          onCancelClick={() => setIsConfirmationModalOpen(false)}
          onContinueClick={handleConfirmToggleOff}
        />
      )}
    </div>
  );
};
export default IntegrationOptions;
