import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';

import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import { customer } from '@store/atoms/customer';
import { workspace } from '@store/atoms/workspace';

interface IntegrationStatus {
  status: boolean;
}

export const useGetIntegrationStatus = (
  setStatus: (status: boolean) => void,
) => {
  const currentWorkspace = useRecoilValue(workspace);
  const currentCustomer = useRecoilValue(customer);

  const client = createHttpClient();

  const query = useQuery(
    QUERY_KEYS.INTEGRATIONS.GET_INTEGRATION_STATUS,
    () => {
      return client<IntegrationStatus>({
        url: END_POINTS.INTEGRATIONS.GET_INTEGRATION_STATUS(
          currentCustomer.id,
          currentWorkspace.id,
        ),
      });
    },
    {
      onSuccess: data => {
        if (data?.status !== undefined) {
          setStatus(data.status);
        }
      },
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to fetch integration status',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return query;
};
