import { FC } from 'react';

import { Button, Modal } from '@components/index';

interface IntegrationActionConfirmationModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onCancelClick(): void;
  onContinueClick(): void;
}

export const IntegrationActionConfirmationModal: FC<
  IntegrationActionConfirmationModalProps
> = ({ isOpen, isLoading, onCancelClick, onContinueClick }) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancelClick}>
      <div className="flex flex-col m-4 px-4 py-2 w-[600px]">
        <h3 className="font-medium text-base">Turn Off Pencil Integration?</h3>
        <div className="mt-2 mb-5 text-sm text-dark-2">
          Are you sure you want to turn off the Pencil integration? All synced
          brand assets from Brand DNAi will be removed from your Pencil
          workspaces, and they will be disconnected.
        </div>
        <div className="my-3 space-x-2 flex">
          <Button
            variant="outlined"
            label="Keep Integration"
            isDisabled={isLoading}
            onClick={onCancelClick}
            classOverride="font-bold !w-1/2 border-gray-6"
          />
          <Button
            variant="contained"
            label="Turn off"
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={onContinueClick}
            classOverride="font-bold !w-1/2"
          />
        </div>
      </div>
    </Modal>
  );
};
