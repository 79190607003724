import { Link } from 'react-router-dom';

import { AngleBack } from '@assets/icons';
import { PermissionAccess } from '@components/index';
import { GENERAL_CONSTANTS } from '@constants/gereralConstants';
import { UserPermissions } from '@enums/user-permissions';
import RoutesPath from '@routes/constants';

import IntegrationOptions from './components/IntegrationOptions';

const Integrations = () => {
  return (
    <PermissionAccess
      permission={UserPermissions.VIEW_INTEGRATIONS}
      AllowedContent={
        <div className="w-full flex flex-col px-6">
          <div className="flex gap-2 items-center w-full h-[88px] py-12 px-11">
            <Link to={RoutesPath.BRAND_LIBRARY}>
              <AngleBack className="cursor-pointer" />
            </Link>
            <div className="text-2xl font-semibold">
              {GENERAL_CONSTANTS.Integrations}
            </div>
          </div>
          <IntegrationOptions />
        </div>
      }
      redirectIfNoAccess
    />
  );
};

export default Integrations;
