import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import { customer } from '@store/atoms/customer';
import { workspace } from '@store/atoms/workspace';

export const useManageIntegrationStatus = () => {
  const currentWorkspace = useRecoilValue(workspace);
  const currentCustomer = useRecoilValue(customer);

  const client = createHttpClient();

  const mutation = useMutation(
    ({
      isEnabled,
    }: {
      isEnabled: boolean;
      onError: () => void;
      onSuccess?: () => void;
    }) =>
      client({
        url: isEnabled
          ? END_POINTS.INTEGRATIONS.ENABLE_INTEGRATION(
              currentCustomer.id,
              currentWorkspace.id,
            )
          : END_POINTS.INTEGRATIONS.DISABLE_INTEGRATION(
              currentCustomer.id,
              currentWorkspace.id,
            ),
        method: HttpMethod.PATCH,
      }),
    {
      onSuccess: (_, variables) => {
        if (variables?.onSuccess) variables.onSuccess();
        showAlert({
          type: AlertType.SUCCESS,
          message: `Pencil integration turned ${variables.isEnabled ? 'on' : 'off'} successfully`,
        });
      },
      onError: (error: Error, variables) => {
        showAlert({
          type: AlertType.ERROR,
          header: `Failed to ${variables.isEnabled ? 'enable' : 'disable'} Integration with Pencil`,
          message: `${error?.message || 'Request failed'}`,
        });
        variables.onError();
      },
    },
  );

  return mutation;
};
